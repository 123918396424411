(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	const removeElmIfEmpty = () => {
		$('.js-checkEmptyElm').each((idx, val) => {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	}
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	const removeBlockIfListEmpty = () => {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each((idx, val) => {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	const setShareLinks = () => {
		$('.js-share').each((idx, ele) => {
			let url = encodeURIComponent(document.URL);
			let title = encodeURIComponent(document.title);
			$(ele).attr('href', $(ele).attr('href').replace(/#link/ig, url).replace(/#text/ig, title));
		});
	};
	const modalImg = () => {
		$('.mod-modalImg').on('click', (e) => {
			let HTML = '<div class="mod-modalImg-overlay">';
			HTML +=
				'<div class="mod-modalImg-closeArea"></div>' +
				'<span class="mod-modalImg-closeBtn"><i class="icon icon-close"></i></span>' +
				`${$(e.currentTarget).find('img').prop('outerHTML')}</div>`;
			$('body').append(HTML);

			const $overlay = $('.mod-modalImg-overlay');
			$overlay.addClass('is-active');
			setTimeout(() => {
				$overlay.addClass('is-visible');

				$('.mod-modalImg-closeArea, .mod-modalImg-closeBtn').on('click', () => {
					$overlay.removeClass('is-visible');
					setTimeout(() => {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);
				});
			});
		});
	};
	const modal = () => {
		let scrollTopNow;
		$('.mod-modal-open').on('click', (e) => {
			let HTML = '<div class="mod-modal-overlay">';
			HTML +=
				'<div class="mod-modal-closeArea"></div>' +
				$(`.mod-modal[data-target="${$(e.currentTarget).attr('data-target')}"]`).prop('outerHTML') + '</div>';
			$('body').append(HTML);

			const $overlay = $('.mod-modal-overlay');
			$overlay.addClass('is-active');
			setTimeout(() => {
				$overlay.addClass('is-visible');

				$('.mod-modal-closeArea, .mod-modal-closeBtn, .mod-modal-close').on('click', () => {
					$overlay.removeClass('is-visible');
					$('.container').css({
						position: 'static',
					});
					$('body').removeClass(f);
					$(window).scrollTop(scrollTopNow);
					e.preventDefault();
					setTimeout(() => {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);
				});
			});
			scrollTopNow = $(window).scrollTop();
			$('.container').css({
				position: 'fixed',
				width: '100%',
				top: -1 * scrollTopNow,
				left: 0
			});
			$('body').addClass();
		});
	};
	const setAgeCheck = () => {
		if (!sessionStorage.getItem('ageCheck') && !document.cookie.split(';').some((item) => item.trim().startsWith('ageCheck='))) {
			let HTML = '<div class="mod-modal-overlay">' +
				'<div class="mod-modal-closeArea"></div>' +
				'<div class="mod-ageCheck"><img src="/common/images/logo.svg" alt="白鶴 ロゴ" class="__logo">' +
					'<p class="fs_18 fs_md_15 mod-spacer">このページはお酒に関する<br data-view="sp">情報が含まれておりますので、<br> 20歳未満の方はご覧いただけません。</p>' +
					'<p class="__main">あなたは<br data-view="sp">20歳以上ですか？</p>' +
					'<div class="pure-g space-u-30"><div class="pure-u-1-2 pure-u-md-1 space-u-30-child">' +
					'<span class="mod-button js-ageCheck" data-target="true"><span>はい</span>'+
					'<svg class="icon mod-iconRight"><use xlink:href="/common/svg/sprite.svg#arrow"></use></svg></span></div>' +
					'<div class="pure-u-1-2 pure-u-md-1 space-u-30-child">' +
					'<span class="mod-button js-ageCheck" data-target="false"><span>いいえ</span>' +
					'<svg class="icon mod-iconRight"><use xlink:href="/common/svg/sprite.svg#arrow"></use></svg></span></div></div>' +
					'<div class="__check mt30"><label for="agreement" class="mod-checkbox">' +
					'<input type="checkbox" class="mod-checkbox-input js-setCookie" name="agreement" id="agreement" value="agree">' +
					'<span class="mod-checkbox-text">次回から表示させない</span></label></div>' +
				'</div></div>';
			$('body').append(HTML);

			const $overlay = $('.mod-modal-overlay');
			$overlay.addClass('is-active');
			setTimeout(() => {
				$overlay.addClass('is-visible');

				// 「はい」クリック
				$('.js-ageCheck[data-target="true"]').on('click', (e) => {
					sessionStorage.setItem('ageCheck', 'true');

					$overlay.removeClass('is-visible');
					$('.container').css({
						position: 'static',
					});
					$('body').removeClass(f);
					e.preventDefault();
					setTimeout(() => {
						$overlay.removeClass('is-active');
						$overlay.remove();
					}, 400);

					// チェックされていたらcookie登録
					if ($('.js-setCookie').prop('checked')) {
						document.cookie = 'ageCheck=true; max-age=7776000; path=/';
					} else {
						document.cookie = 'ageCheck=; max-age=0';
					}
				});

				$('.js-ageCheck[data-target="false"]').on('click', (e) => {
					e.preventDefault();
					location.href = '/';
				});
			});

			$('.container').css({
				position: 'fixed',
				width: '100%',
				top: 0,
				left: 0
			});
			$('body').addClass();
		}
	};
	const setAgeCheckGlobal = () => {
		let region = FUNCTIONS.va.pathname.split('/')[2];
		if (FUNCTIONS.va.pathname.indexOf('age.html') === -1) {
			if (!sessionStorage.getItem(`ageCheck_${region}`) && !document.cookie.split(';').some((item) => item.trim().startsWith(`ageCheck_${region}`))) {
				sessionStorage.setItem(`ageCheck_${region}`, FUNCTIONS.va.pathname);
				location.href = `/global/${region}/age.html`;
			}
		} else {
			if (!sessionStorage.getItem(`ageCheck_${region}`)) {
				sessionStorage.setItem(`ageCheck_${region}`, `/global/${region}/`);
			}
			$('.js-enter').attr('href', sessionStorage.getItem(`ageCheck_${region}`));

			$('.js-setCookie').on('change', (e) => {
				if ($(e.currentTarget).prop('checked')) {
					document.cookie = `ageCheck_${region}=true; max-age=7776000; path=/global/${region}/`;
				} else {
					document.cookie = `ageCheck_${region}=; max-age=0`;
				}
			});
		}
	};

	removeElmIfEmpty();
	removeBlockIfListEmpty();
	setShareLinks();
	modalImg();
	modal();

	// 年齢確認（国内）：prod酒カテゴリ配下商品詳細のみ
	let map4 = FUNCTIONS.va.pathname.split('/')[4];
	if ((FUNCTIONS.va.pathname.indexOf('/product/sake/') !== -1 && map4) ||
			(FUNCTIONS.va.pathname.indexOf('/product/liqueur/') !== -1 && map4) ||
			(FUNCTIONS.va.pathname.indexOf('/product/wine/') !== -1 && map4) ||
			(FUNCTIONS.va.pathname.indexOf('/product/others/') !== -1 && map4)) {
		setAgeCheck();
	}
	// 年齢確認（グローバル）：globalTOP以外の全ページで発動
	else if (FUNCTIONS.va.pathname.indexOf('/global/') !== -1 && FUNCTIONS.va.pathname !== '/global/' && FUNCTIONS.va.pathname !== '/global/index.html') {
		setAgeCheckGlobal();
	}


})(window.jQuery, window.FUNCTIONS);
